@use '@angular/material' as mat;
@mixin mail-confirm-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $accent: map-get($theme, accent);
  $is-dark: map-get($theme, is-dark);
  $warn: map-get($theme, warn);
  mail-confirm {
    #mail-confirm {
      #mail-confirm-form-wrapper {
        @if ($is-dark) {
          background: mat.get-color-from-palette($myplane-blue, 500);
        } @else {
          background: map-get($background, card);
        }
      }
    }
  }
}
