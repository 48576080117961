@use '@angular/material' as mat;
@mixin forgot-password-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $accent: map-get($theme, accent);
  $is-dark: map-get($theme, is-dark);

  forgot-password {
    #forgot-password {
      #forgot-password-form-wrapper {
        @if ($is-dark) {
          background: mat.get-color-from-palette($myplane-blue, 500);
        } @else {
          background: map-get($background, card);
        }
      }
    }
  }
}
