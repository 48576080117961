@use '@angular/material' as mat;
@mixin register-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $accent: map-get($theme, accent);
  $is-dark: map-get($theme, is-dark);

  register {
    #register {
      #register-form-wrapper {
        @if ($is-dark) {
          background: mat.get-color-from-palette($myplane-blue, 500);
        } @else {
          background: map-get($background, card);
        }

        #register-form {
          .separator {
            color: map-get($foreground, divider);

            .text {
              &:before,
              &:after {
                border-top-color: map-get($foreground, divider);
              }
            }
          }
        }
      }
    }
  }
}
