@mixin toolbar-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $is-dark: map-get($theme, is-dark);

  toolbar {
    .toolbar-separator {
      background: map-get($foreground, divider);
    }
  }
}
